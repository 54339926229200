import React, {useEffect, useState} from "react";
import useIsStillMounted from "./useIsStillMounted";

export default () => {
  const isMounted = useIsStillMounted();
  const [position, setPosition] = useState<GeolocationPosition>();
  const [error, setError] = useState<GeolocationPositionError>();

  useEffect(() => {
    const success: PositionCallback = (position: GeolocationPosition) => {
      console.log("position", position);
      if (isMounted()) {
        setPosition(position);
      }
    };
    const error: PositionErrorCallback = (err: GeolocationPositionError) => {
      if (isMounted()) {
        setError(err);
      }
    };
    navigator.geolocation.getCurrentPosition(success, error);
  }, [isMounted, setPosition, setError]);
  if (position?.coords) {
    const { latitude, longitude, accuracy } = position.coords;
    return <div>position={JSON.stringify({ latitude, longitude, accuracy })}</div>
  }
  return <div>
    <div>(no position)</div>
    { error && <div>ERR: {error.message}</div> }
  </div>;
};
